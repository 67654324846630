
import moment from 'moment';
export default {
  data: () => ({
    loading: false,
    startTime: new Date(),
    // loadingSeconds: 0,
    displayText: '',
    loadingText: [
      // Bestehende Sprüche
      'Moment mal, ich muss kurz meine digitalen Zahnräder sortieren...',
      'Ich muss kurz meine digitalen Zahnräder sortieren...',
      'Einen Augenblick, die Hamster im Laufrad brauchen eine Verschnaufpause...',
      'Die Einsen und Nullen werden gerade alphabetisch sortiert...',
      'Warte kurz, ich falte noch schnell das Internet...',
      'Lade Quantenfluktuationen... bitte nicht beobachten!',
      'Die Pixel werden noch schnell auf Hochglanz poliert...',
      'Moment, ich hole nur kurz neue Bytes aus dem Datenkeller...',
      'Die Server-Elfen arbeiten mit Hochdruck...',
      'Bitte warten, die Cloud wird noch entstaubt...',
      'Die Daten werden noch kurz durch die Qualitätskontrolle geschickt...',
      'Einen Moment noch, die Algorithmen müssen sich erst aufwärmen...',

      // Neue Sprüche mit Therapiebezug
      'Die Sprachtherapie-Roboter machen gerade Stimmübungen...',
      'Unsere digitalen Logopäden artikulieren die Daten...',
      'Moment, die Feinmotorik der Server wird noch justiert...',
      'Die Ergotherapie-KI macht gerade Handübungen...',
      'Bitte warten, die Datenbank macht noch ihre Mundmotorik-Übungen...',
      'Die Server machen gerade ihre Atemübungen...',
      'Moment, wir üben noch schnell das Zungenbrecher-Protokoll...',
      'Die Daten werden gerade therapeutisch begleitet...',
      'Unsere KI macht gerade ihre Sprechmotorik-Übungen...',
      'Die Bytes werden noch ergotherapeutisch behandelt...',
      'Moment, die Datenbank macht noch ihre Koordinationsübungen...',
      'Die Server-Synapsen werden noch verschaltet...',

      // Allgemein lustige neue Sprüche
      'Die Quantencomputer meditieren noch kurz...',
      'Unsere KI-Hamster brauchen eine kleine Verschnaufpause...',
      'Die Daten-Elfen tanzen noch einen kleinen Reigen...',
      'Die Binary-Bienen sammeln noch den letzten Code-Honig...',
      'Die Pixel-Pandas kauen noch ihr digitales Bambusfrühstück...',
      'Die Server machen gerade Yoga...',
      'Die Datenpakete spielen noch kurz Verstecken...',
      'Die Cloud macht noch ihr Morgenstretch...',
      'Die Algorithmen trinken noch schnell ihren Morgenkaffee...',
      'Die Firewall bürstet sich noch die Zähne...',
      'Die Cookies werden noch frisch gebacken...',
      'Die Datenbank macht noch ihr Nickerchen...',
      'Die Server spielen noch eine Runde Schere-Stein-Papier...',
      'Die Bits und Bytes spielen noch Fangen...',
      'Die Cache-Katzen schnurren noch ihre Daten zurecht...',
      'Die Router machen noch ihre Morgengymnastik...',
      'Die Prozessoren puzzeln noch die letzten Teile zusammen...',
      'Die Festplatten drehen noch ihre Aufwärmrunden...',
      'Die Maus macht noch Käse-Pause...',
      'Die Pixel spielen noch Tetris...',

      // Weitere therapeutische Sprüche
      'Die Sprachzentren der Server synchronisieren sich...',
      'Die Feinmotorik-Einheit wird noch kalibriert...',
      'Die therapeutischen Algorithmen machen Dehnübungen...',
      'Die Logopädie-Logs werden noch formatiert...',
      'Die Ergotherapie-Engine wärmt sich auf...',
      'Die Sprachmodule machen Zungenübungen...',
      'Die motorischen Einheiten werden noch justiert...',
      'Die Therapie-Datenbank macht ihre Morgenübungen...',
      'Die Handmotorik-Sensoren werden noch eingestellt...',
      'Die Spracherkennungs-KI räuspert sich noch...',

      // Weitere allgemeine lustige Sprüche
      'Die digitalen Gärtner gießen noch die Datenblumen...',
      'Die Binary-Bären machen Winterschlaf-Pause...',
      'Die Pixel-Pinguine rutschen noch ins Ziel...',
      'Die Code-Kolibris sammeln noch Nektar...',
      'Die Server-Schnecken kriechen ins Ziel...',
      'Die Daten-Delfine schwimmen noch eine Runde...',
      'Die Cache-Chamäleons wechseln noch die Farbe...',
      'Die Prozessor-Pandas kauen noch am Bambus...',
      'Die Router-Raben sammeln noch glänzende Bits...',
      'Die Festplatten-Füchse jagen noch Datenpakete...',

      // Noch mehr Therapiebezug
      'Die Artikulations-Algorithmen werden geschärft...',
      'Die Mundmotorik-Module machen Aufwärmübungen...',
      'Die Sprachtherapie-Synapsen verknüpfen sich...',
      'Die Feinmotorik-Firmware wird aktualisiert...',
      'Die Ergotherapie-Einheiten kalibrieren sich...',
      'Die Sprachzentren-Server synchronisieren noch...',
      'Die Therapieplan-Drohnen schwärmen aus...',
      'Die Übungs-Updates werden installiert...',
      'Die Therapie-Terminals starten durch...',
      'Die Behandlungs-Bytes werden sortiert...',

      // Weitere humorvolle Varianten
      'Die Server-Schildkröten machen einen Zwischensprint...',
      'Die Daten-Dinosaurier erwachen aus der Digitalisierung...',
      'Die Prozessor-Papageien plappern noch Binärcode...',
      'Die Cache-Kängurus hüpfen durch den Arbeitsspeicher...',
      'Die Festplatten-Flamingos stehen noch auf einem Bein...',
      'Die Router-Regenwürmer graben neue Datentunnel...',
      'Die Binary-Biber bauen noch am Datendamm...',
      'Die Pixel-Pfauen zeigen ihr schönstes Farbenspiel...',
      'Die Code-Koalas klammern sich an den letzten Commit...',
      'Die Server-Seepferdchen schwimmen noch eine Ehrenrunde...'
]
  }),
  methods: {
    start(text = '') {
      this.displayText = text;
      this.startTime = new Date();
      this.loading = true
      // this.secondsLoading();
    },
    finish() {
      this.loading = false
    },
    // secondsLoading() {
    //   let diff = moment().local('de').diff(this.startTime, 'seconds', false);
    //   this.loadingSeconds = diff;
    //   setTimeout(() => {
    //     if (!this.loading) return;
    //     return this.secondsLoading();
    //   }, 1000)
    // }
  },
  computed: {
    randomLoadingText() {
      return this.loadingText[Math.floor(Math.random() * this.loadingText.length)];
    }
  }
}
